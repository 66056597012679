<template>
  <div>
    <div class="e-breadcrumb">
      <span style="margin-left: -10px" @click="closeEvent">预约设置</span>
      > 编辑
    </div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div style="width: 1000px">
          <el-form ref="form" label-width="230px" style="margin-top: 30px">
            <el-form-item size="small" label="预约开始时间:" required>
              <el-time-select
                style="width: 100%;"
                v-model="item.startTime"
                value-format="HH:mm"
                :picker-options="{
                 start: '00:00',
                 step: '00:30',
                 end: '23:59'
                 }"
                placeholder="选择时间">
              </el-time-select>
            </el-form-item>
            <el-form-item size="small" label="预约结束时间:" required>
              <el-time-select
                style="width: 100%;"
                v-model="item.endTime"
                value-format="HH:mm"
                :picker-options="{
                 start: '00:00',
                 step: '00:30',
                 end: '23:59'
                 }"
                placeholder="选择时间">
              </el-time-select>
            </el-form-item>

            <el-form-item size="small" label="自助预约需提前的天数:" required>
              <el-select style="width: 94%" v-model="item.advanceDay" placeholder="请选择">
                <el-option
                  v-for="item in days"
                  :key="item.dictValue"
                  :label="item.name"
                  :value="item.dictValue">
                </el-option>
              </el-select>
              <span style="margin-left: 10px">天</span>
            </el-form-item>

            <el-form-item size="small" label="当天预约需提前:" required>
              <el-select style="width: 94%" v-model="item.advanceMin" placeholder="请选择">
                <el-option
                  v-for="item in mins"
                  :key="item.dictValue"
                  :label="item.name"
                  :value="item.dictValue">
                </el-option>
              </el-select>
              <span style="margin-left: 10px">分钟</span>
            </el-form-item>

            <el-form-item size="small" label="预约时长占用模式:" required>
              <el-radio-group v-model="item.occupancyMode">
                <el-radio :label="'0'">预约时长叠加</el-radio>
                <el-radio :label="'1'">预约时长累计</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item size="small" style="margin-top: -8px" label="预约策略:" required>
              <el-radio-group v-model="item.reservationStrategy">
                <el-radio :label="'0'">半点预约，半小时可预约一客</el-radio>
                <el-radio :label="'1'">0整点预约，一小时可预约一客</el-radio>
              </el-radio-group>
              <p>
                <el-checkbox v-model="item.isAmp">预约时不可选择预约多人</el-checkbox>
                <span style="margin-left: 20px;color: #c0c4cc">如不勾选，顾客在预约时可选择预约的人数</span>
              </p>
              <p>
                <el-checkbox v-model="item.isSendSms">预约后发送确认短信</el-checkbox>
              </p>
              <p>
                <el-checkbox v-model="item.isGuli">预约时自动获取用户位置</el-checkbox>
                <span style="margin-left: 20px;color: #c0c4cc">启用后将获取用户位置信息并附加在备注字段</span>
              </p>
              <div>
                <el-form-item size="small" label-width="100px" style="margin-left: -30px;margin-top: 5px" label="预约提示:">
                  <el-input v-model="item.appointmentReminder" style="width: 200px" placeholder="请输入预约提示"></el-input>
                  <span style="margin-left: 20px;color: #c0c4cc">预约提示将显示在预约列表页和确认页，商户可根据需求填写</span>
                </el-form-item>
              </div>
              <p style="margin-top: -8px">
                <el-checkbox v-model="item.isBroadcast">预约播报手艺人姓名和编号</el-checkbox>
                <span style="margin-left: 20px;color: #c0c4cc">启用后有预约时将播报被预约手艺人的工号和姓名</span>
              </p>
              <p>
                <el-checkbox v-model="item.isLaunchAdvanced">启用高级预约模式</el-checkbox>
              </p>
              <p v-if="item.isLaunchAdvanced">
                <el-checkbox v-model="item.launchAutoEntry">高级预约的预约项目自动入单</el-checkbox>
              </p>
              <p>
                <el-checkbox v-model="item.isShowPrice">预约时显示项目价格</el-checkbox>
              </p>
            </el-form-item>
            <el-form-item size="small" label="可预约天数:">
              <el-input v-model="item.daysAvailable" placeholder="请输入可预约天数"></el-input>
            </el-form-item>

            <el-form-item size="small" label="预约限制:">
              <p>
                <el-checkbox v-model="item.advanceDeposit">预约需要缴纳保证金</el-checkbox>
                <el-input :disabled="!item.advanceDeposit" style="margin-left: 10px;width: 60px"
                          v-model="item.advanceDepositValue"></el-input>
                <span style="margin-left: 10px;">元，爽约后不退还</span>
              </p>
              <p style="margin-top: 8px">
                <el-checkbox v-model="item.onlyIndividualCustomers">仅散客需要缴纳</el-checkbox>
                <span style="margin-left: 20px;color: #c0c4cc">如果在可取消预约时段内取消的，也可以正常退款；会员可使用卡金作为保证金</span>
              </p>
              <p style="margin-top: 8px">
                <el-checkbox v-model="item.breakAppointment">连续爽约</el-checkbox>
                <el-input :disabled="!item.breakAppointment" style="margin-left: 10px;width: 60px"
                          v-model="item.breakAppointmentFrequency"></el-input>
                <span style="margin-left: 10px;">次后取消用户的在线预约功能</span>
                <span style="margin-left: 10px;color: #c0c4cc">可在收银台或小掌柜再次开启</span>
              </p>
              <p style="margin-top: 8px">
                <el-checkbox v-model="item.mostCustomersDay">顾客每天最多能有</el-checkbox>
                <el-input :disabled="!item.mostCustomersDay" style="margin-left: 10px;width: 60px"
                          v-model="item.mostCustomersDayValue"></el-input>
                <span style="margin-left: 10px;">个有效的预约</span>
              </p>
              <p style="margin-top: 8px">
                <el-checkbox v-model="item.successAppointment">最多只能有</el-checkbox>
                <el-input :disabled="!item.successAppointment" style="margin-left: 10px;width: 60px"
                          v-model="item.successAppointmentValue"></el-input>
                <span style="margin-left: 10px;">个成功的有效预约</span>
              </p>
              <p style="margin-top: 8px">
                <el-checkbox v-model="item.displayDuration">小程序预约时不显示服务时长</el-checkbox>
              </p>

            </el-form-item>

          </el-form>
          <div style="margin-bottom: 30px;margin-top:30px;margin-left: 615PX">
            <el-button size="small" @click="closeEvent">取 消</el-button>
            <el-button size="small" type="primary" @click="saveBbiAppointment">确 定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        id: '',

        item: {},

        days: [],
        mins: [],

      }
    },
    created() {
      this.id = this.$route.query.id
      this.getAppointment()
      this.getAppointmentDaysList()
      this.getAppointmentMinsList()
    },
    methods: {
      async getAppointmentDaysList() {
        let res = await this.$get('/admin/getDictDetailList/APPOINTMENT_DAYS/' + Math.random());
        this.days = res;
      },
      async getAppointmentMinsList() {
        let res = await this.$get('/admin/getDictDetailList/APPOINTMENT_MINS/' + Math.random());
        this.mins = res;
      },
      async getAppointment() {
        let res = await this.$get("/admin/getAppointment", {id: this.id})
        if (res.code == 200) {
          this.item = res.item
        }
      },

      closeEvent() {
        this.$router.push("/appointments/appointment")
      },
      async saveBbiAppointment() {

        if (!this.item.startTime) {
          this.$message.error("请选择预约开始时间");
          return
        }
        if (!this.item.endTime) {
          this.$message.error("请选择结束开始时间");
          return
        }
        if (!this.item.advanceDay) {
          this.$message.error("请选择自助预约需提前的天数");
          return
        }
        if (!this.item.advanceMin) {
          this.$message.error("请选择当天预约需提前");
          return
        }

        let data = {
          id: this.item.id,
          startTime: this.item.startTime,
          endTime: this.item.endTime,
          advanceDay: this.item.advanceDay,
          advanceMin: this.item.advanceMin,
          occupancyMode: this.item.occupancyMode,
          reservationStrategy: this.item.reservationStrategy,
          appointmentReminder: this.item.appointmentReminder,
          launchAdvanced: this.item.launchAdvanced,
          isAmp: this.item.isAmp,
          isSendSms: this.item.isSendSms,
          isGuli: this.item.isGuli,
          isBroadcast: this.item.isBroadcast,
          isShowPrice: this.item.isShowPrice,
          isLaunchAdvanced: this.item.isLaunchAdvanced,
          launchAutoEntry: this.item.launchAutoEntry,
          daysAvailable: this.item.daysAvailable,
          advanceDeposit: this.item.advanceDeposit,
          advanceDepositValue: this.item.advanceDepositValue,
          onlyIndividualCustomers: this.item.onlyIndividualCustomers,
          breakAppointment: this.item.breakAppointment,
          breakAppointmentFrequency: this.item.breakAppointmentFrequency,
          mostCustomersDay: this.item.mostCustomersDay,
          mostCustomersDayValue: this.item.mostCustomersDayValue,
          successAppointment: this.item.successAppointment,
          successAppointmentValue: this.item.successAppointmentValue,
          displayDuration: this.item.displayDuration,
        };

        let res = await this.$post("/admin/save_bbiAppointment", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$router.push("/appointments/appointment")
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>
